import usePlan from "hooks/usePlan";
import {
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Outlet, useLocation, useParams } from "react-router";

import { URL_PROJECT_DETAIL } from "constants";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Puff from "react-loading-icons/dist/esm/components/puff";
import { useNavigate } from "react-router";
import Sidebar from "./layout/Sidebar";
import Header from "./layout/header/Header";
// // const Overview = lazy(() => import("pages/Overview"));
// // const Team = lazy(() => import("pages/Team"));
// // const Forms = lazy(() => import("pages/Forms"));
// const Edit = lazy(() => import("./forms/Edit"));
// const Map = lazy(() => import("pages/Map"));
// // const Responses = lazy(() => import("pages/Responses"));

export const BaseContext = createContext();

function Base() {
  const param = useParams();
  const location = useLocation();
  const data = location.state
  // const proj_id = data?.id;
  let slug = param?.slug;
  // if (proj_id) slug = null
  const [miniSidebar, setMiniSidebar] = useState(true);
  const axiosPrivate = useAxiosPrivate()
  const { plan } = usePlan();
  const loadingRef = useRef();

  const navigate = useNavigate();

  const [proj, setProj] = useState(data);
  const [apiUrl, setApiUrl] = useState(null);

  const showLoading = () => {
    loadingRef?.current.classList.remove("hidden");
  };
  const hideLoading = () => {
    loadingRef?.current.classList.add("hidden");
  };

  useEffect(() => {
    async function fetchProj() {
      await axiosPrivate
        .get(`${URL_PROJECT_DETAIL}?slug=${slug}`)
        .then((res) => {
          if (!res?.data?.data?.id > 0)
            navigate(`/dashboard`);
          else if (res.data.data.is_expired)
            navigate(`/dashboard`);
          else {
            setProj(res.data.data);
            setApiUrl(res.data.data?.api_url)
          }
        })
        .catch((error) => {
          console.log(error)
        });
    }
    if (slug)
      fetchProj();
  }, [axiosPrivate, slug]);
  if (proj?.id)
    return (
      <div>
        <div
          ref={loadingRef}
          className="z-[100000000] backdrop-blur-sm fixed top-0 left-0 w-full h-full grid place-items-center hidden bg-black opacity-40"
        >
          <div className="text-center opacity-100">
            <Puff className="mx-auto mb-2" stroke="#ffffff" color="#4fa94d" />
            <span className="text-white"> Processing ...</span>
          </div>
        </div>
        <Header miniSidebar={miniSidebar} proj={proj} showLoading={showLoading} hideLoading={hideLoading} />
        <div className="flex ">
          <Sidebar
            miniSidebar={miniSidebar}
            setMiniSidebar={setMiniSidebar}
            proj={proj}
          />
          <div
            className={`${miniSidebar ? "ml-16" : "ml-52"
              } p-4 h-screen w-full flex overflow-auto`}
          >
            <div className="mt-14 w-full">
              <BaseContext.Provider value={{ proj, setProj, plan, apiUrl, showLoading, hideLoading }}>
                <Outlet />
              </BaseContext.Provider>

            </div>
          </div>
        </div>
      </div>
    );
  else return "Loading ......";
}

export default Base;
